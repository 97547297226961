import React, { useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
// import Header from './components/navigation/Header';
import SideMenu from './components/side-menu/SideMenu';
import ScrollTop from './components/scroll-top/ScrollTop';
import FixedBottomDrawer from './components/FixedBottomDrawer/FixedBottomDrawer';

const Layout = ({ route }) => {
  const {
    MetaTitle,
    MetaDescription,
    MetaKeywords,
    CanonicalLinks,
    GTMCode,
    PreSiteScripts,
    PostSiteScripts,
    WebSchema,
    OrgSchema,
    RobotsIndex,
    RobotsFollow,
    OrgSchima,
    FinancialProductSchema,

    OgTitle,
    OgDescription,
    OgImage,
    OgType,
    OgUrl,

    TwitteTitle,
    TwitterCards,
    TwitterDescription,
    TwitterImage,
  } = route?.fields || {};

  // console.log('route?.fields', route?.fields);

  useEffect(() => {
    if (PreSiteScripts?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(PreSiteScripts?.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [PreSiteScripts?.value]);

  useEffect(() => {
    if (PostSiteScripts?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(PostSiteScripts?.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.body.appendChild(newScript);
      });
    }
  }, [PostSiteScripts?.value]);

  // New Schema

  useEffect(() => {
    if (WebSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(WebSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [WebSchema?.value]);

  useEffect(() => {
    if (OrgSchima?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(OrgSchima.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [OrgSchima?.value]);

  useEffect(() => {
    if (FinancialProductSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(FinancialProductSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [FinancialProductSchema?.value]);

  // useEffect(() => {
  //   if (WebSchema?.value) {
  //     const parser = new DOMParser();
  //     const scriptsDoc = parser.parseFromString(WebSchema.value, 'text/html');
  //     const scripts = scriptsDoc.getElementsByTagName('script');

  //     Array.from(scripts).forEach((script) => {
  //       const newScript = document.createElement('script');
  //       newScript.type = script.type;
  //       newScript.textContent = script.textContent;
  //       document.head.appendChild(newScript);
  //     });
  //   }
  // }, [WebSchema?.value]);

  // useEffect(() => {
  //   if (OrgSchema?.value) {
  //     const parser = new DOMParser();
  //     const scriptsDoc = parser.parseFromString(OrgSchema.value, 'text/html');
  //     const scripts = scriptsDoc.getElementsByTagName('script');

  //     Array.from(scripts).forEach((script) => {
  //       const newScript = document.createElement('script');
  //       newScript.type = script.type;
  //       newScript.textContent = script.textContent;
  //       document.head.appendChild(newScript);
  //     });
  //   }
  // }, [OrgSchema?.value]);

  // useEffect(() => {
  //   if (FinancialProductSchema?.value) {
  //     const parser = new DOMParser();
  //     const scriptsDoc = parser.parseFromString(FinancialProductSchema.value, 'text/html');
  //     const scripts = scriptsDoc.getElementsByTagName('script');

  //     Array.from(scripts).forEach((script) => {
  //       const newScript = document.createElement('script');
  //       newScript.type = script.type;
  //       newScript.textContent = script.textContent;
  //       document.head.appendChild(newScript);
  //     });
  //   }
  // }, [FinancialProductSchema?.value]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const buttonCloseTicker = document.querySelector('.ticker_close');
      const removeClassDiv = document.querySelector('.ticker-header-parent');

      const handleButtonClick = () => {
        if (removeClassDiv) {
          removeClassDiv.classList.remove('tickerOpen-parent');
        }
      };

      const handleScroll = () => {
        if (removeClassDiv) {
          const scrollPositionY = window.scrollY;
          if (scrollPositionY > 0) {
            removeClassDiv.classList.add('tickerOpen-parent-scroll');
          } else {
            removeClassDiv.classList.remove('tickerOpen-parent-scroll');
          }
        }
      };

      if (buttonCloseTicker) {
        buttonCloseTicker.addEventListener('click', handleButtonClick);
      }
      window.addEventListener('scroll', handleScroll);

      // Cleanup event listeners on unmount
      return () => {
        if (buttonCloseTicker) {
          buttonCloseTicker.removeEventListener('click', handleButtonClick);
        }
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{MetaTitle?.value || 'Page'}</title>
        <meta name="description" content={MetaDescription?.value || ''} />
        <meta name="keywords" content={MetaKeywords?.value || ''} />

        <meta property="og:title" content={OgTitle?.value} />
        <meta property="og:description" content={OgDescription?.value} />
        <meta property="og:image" content={OgImage?.value?.src} />
        <meta property="og:type" content={OgType?.value} />
        <meta property="og:url" content={OgUrl?.value} />

        <meta name="twitter:card" content={TwitterCards?.value} />
        <meta name="twitter:title" content={TwitteTitle?.value} />
        <meta name="twitter:description" content={TwitterDescription?.value} />
        <meta name="twitter:image" content={TwitterImage?.value?.src} />

        {CanonicalLinks?.value?.href && <link rel="canonical" href={CanonicalLinks?.value?.href} />}
        <link rel="preconnect" href={process.env.React_App_CDN_URL} />

        {RobotsIndex?.value && RobotsFollow?.value && <meta name="robots" content="index,follow" />}
        {!RobotsIndex?.value && RobotsFollow?.value && (
          <meta name="robots" content="noindex,follow" />
        )}
        {RobotsIndex?.value && !RobotsFollow?.value && (
          <meta name="robots" content="index,nofollow" />
        )}

        {!RobotsIndex?.value && !RobotsFollow?.value && (
          <meta name="robots" content="noindex,nofollow" />
        )}

        {GTMCode?.value && (
          <script>
            {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({
                'gtm.start': new Date().getTime(),
                event:'gtm.js'
              });
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.defer = true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTMCode?.value}');
          `}
          </script>
        )}
      </Helmet>
      <FixedBottomDrawer />
      <ScrollTop />

      <main>
        <Placeholder name="jss-main" rendering={route} />
      </main>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
