import React, { useState, useEffect } from 'react';

const ScrollTop = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  const handleClick = () => {
    if (window.scrollY !== 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`btnScrollTop ${showScrollTop ? 'activated' : ''}`} onClick={handleClick}></div>
  );
};

export default ScrollTop;
