import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';

const FixedBottomDrawerQuery = gqlLoader('./FixedBottomDrawerQuery.graphql');

const FixedBottomDrawer = (props) => {
  const { drawer } = props?.data;
  useEffect(() => {
    const toggleMenu = () => {
      const innerDiv = document.querySelector('.mobfixed-downloadbtn');
      if (innerDiv.classList.contains('visible-sticky-mob')) {
        innerDiv.classList.remove('visible-sticky-mob');
      } else {
        innerDiv.classList.add('visible-sticky-mob');
      }
    };

    const hamburger = document.querySelector('.hamburger');
    if (hamburger) {
      hamburger.addEventListener('click', toggleMenu);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (hamburger) {
        hamburger.removeEventListener('click', toggleMenu);
      }
    };
  }, []);

  return (
    <div className="mobfixed-downloadbtn">
      <div className="accordion mobbtn-accordion" id="accordionExample-mobxm">
        <div className="accordion-item">
          <div className="accordion-header" id="fixedheading-mob">
            <button
              className="accordion-button mob-toggleClassBtn collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne-fixedmob"
              aria-expanded="true"
              aria-controls="collapseOne-fixedmob"
            >
              <div className="btninner-mob">
                <span className="spantext-mb">{props?.t('Download')}</span>{' '}
                <span className="appimg-abcd">
                  <img
                    src={drawer?.image1?.src}
                    width="100"
                    height="100"
                    alt="Download App"
                    loading="lazy"
                  />
                </span>
              </div>
              <div className="outerdropArrow-span">
                <div className="dropArrow-span"></div>
              </div>
            </button>
          </div>
          <div
            id="collapseOne-fixedmob"
            className="accordion-collapse collapse"
            aria-labelledby="fixedheading-mob"
            data-bs-parent="#accordionExample-mobxm"
          >
            <div className="accordion-body">
              <div className="mobDownloadApp-body">
                <div className="mobDownloadApp-body-content">
                  <div className="title-heading-wrap">{drawer?.heading?.value}</div>
                  <p>{drawer?.subheading?.value}</p>
                  <span>
                    {drawer?.subheading2?.value}{' '}
                    <a href={drawer?.siteLink?.url}>{drawer?.siteLink?.text}</a>{' '}
                    {drawer?.subheading3?.value}
                  </span>
                  <div className="download_btn_ggrid">
                    <a className="know_more_about-us" href={drawer?.appleapplink?.url}>
                      <img
                        className="dc-apple-store"
                        src={drawer?.appleappimage?.src}
                        alt="Discover Convenience Like Never Before"
                        loading="lazy"
                      />{' '}
                      {drawer?.appleapplink?.text}
                    </a>
                    <a className="know_more_about-us" href={drawer?.googleapplink?.url}>
                      <img
                        className="dc-play-store"
                        src={drawer?.googleappimage?.src}
                        alt="Discover Convenience Like Never Before"
                        loading="lazy"
                      />{' '}
                      {drawer?.googleappLink?.text}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(
  GraphQLData(FixedBottomDrawerQuery, { name: 'data' })(FixedBottomDrawer)
);
