import React from 'react';
import { Helmet } from 'react-helmet';
// import Header from './components/navigation/Header';
// import Footer from './components/navigation/Footer';

const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" media="all" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/swiper-bundle.min.css"
          media="all"
        />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" media="all" />
        <link rel="stylesheet" type="text/css" href="/assets/css/abc_footer.css" media="all" />
        <link
          key="nouislider"
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/15.7.1/nouislider.css"
        />
        <link
          key="style"
          rel="stylesheet"
          type="text/css"
          href="/assets/css/style.css"
          media="all"
        />
        <link
          key="categorypage-style"
          rel="stylesheet"
          type="text/css"
          href="/assets/css/categorypage-style.css"
          media="all"
        />
        <link
          key="navigation"
          rel="stylesheet"
          type="text/css"
          href="/assets/css/navigation.css"
          media="all"
        />
        <link
          key="common"
          rel="stylesheet"
          type="text/css"
          href="/assets/css/common.css"
          media="all"
        />
        <link rel="stylesheet" type="text/css" href="/assets/css/jss-override.css" media="all" />
      </Helmet>
      {/* <Header /> */}
      <div
        style={{
          textAlign: 'center',
          marginTop: '300px',
          marginBottom: '300px',
        }}
      >
        <h1>Page not found</h1>
        <p>We&apos;re sorry, this page is no longer available.</p>
        <p>
          Call{' '}
          <a href="tel:18002707000" style={{ color: '#c91429' }}>
            1800 270 7000
          </a>{' '}
          if you need further assistance.
        </p>
      </div>
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default NotFound;
