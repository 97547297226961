import React, { useState, useEffect, useMemo } from 'react';
import TopNavigation from './TopNavigation.js';
import MobileNavigation from './MobileNavigation.js';
import MegaMenuQuickServices from './MegaMenuQuickServices.js';
import MegaMenuHolder from './MegaMenuHolder.js';
// import SearchMenu from './SearchMenu.js';
import { withTranslation } from 'react-i18next';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import Tools from './Tools.js';
import Ticker from '../ticker/Ticker.js';
import { isMobile } from 'react-device-detect';
import BottomTicker from '../ticker/BottomTicker.js';
import { handleMainNavClick } from '../../lib/customGA4';

// const HeaderQuery = gqlLoader('./Header.graphql');

const Header = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [isToolsShow, setIsToolsShow] = useState(false);
  const [isBodyOverlay5, setIsBodyOverlay5] = useState(false);
  const [toolsData, setToolsData] = useState(null);
  const [quickServiceData, setQuickServiceData] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // New state for menu open
  const [isSticky, setIsSticky] = useState(false);
  const [bannerCountdownItem, setBannerCountdownItem] = useState(null);
  const [clickedTab, setClickedTab] = useState('');

  useEffect(() => {
    const body = document.querySelector('body');

    if (isBodyOverlay5) {
      body.classList.add('body_overlay5');
    } else {
      body.classList.remove('body_overlay5');
    }
  }, [isBodyOverlay5]);

  const switchTab = (newTab) => {
    if (activeTab === newTab) {
      setActiveTab('');
      setIsBodyOverlay5(false);
      setIsShow(false);
      setIsToolsShow(false);
    } else {
      setActiveTab(newTab);
      setIsBodyOverlay5(true);
      setIsShow(false);
      setIsToolsShow(false);
    }
  };

  const handleTabClick = (tabId) => {
    if (
      tabId === 'searchmenu' ||
      tabId === 'quickservices_megamenu' ||
      tabId === 'resources_megamenu'
    ) {
      setIsBodyOverlay5(true);
    } else {
      setIsBodyOverlay5((prevIsBodyOverlay5) => !prevIsBodyOverlay5);
    }
    switchTab(tabId);
  };

  const toggleMegaMenu = (name) => {
    handleTabClick(name);
    setActiveTab('');
    setIsActive(false);
    setIsShow(!isShow);
    setIsToolsShow(false);
    setIsBodyOverlay5(!isShow);
  };

  const toggleToolsMegaMenu = (name) => {
    handleTabClick(name);
    setActiveTab('');
    setIsActive(false);
    setIsToolsShow(!isToolsShow);
    setIsShow(false);
    setIsBodyOverlay5(!isToolsShow);
  };

  useEffect(() => {
    if (activeTab !== '') {
      setIsShow(false);
      setIsToolsShow(false);
    }
    if (isShow === true) {
      setActiveTab('');
      setIsActive(false);
      setIsToolsShow(false);
    }
    if (isToolsShow === true) {
      setActiveTab('');
      setIsActive(false);
    }
  }, [activeTab, isActive, isShow, isToolsShow]);

  const closeMenu = () => {
    setIsShow(false);
    setIsToolsShow(false);
    setActiveTab('');
    setIsActive(false);
  };

  const handleOverlayClick = (event) => {
    if (isBodyOverlay5) {
      if (event.target.classList.contains('body_overlay5')) {
        closeMenu();
        setIsBodyOverlay5(false);
      }
    }
  };

  useEffect(() => {
    const overlayElement = document.querySelector('.body_overlay5');

    if (overlayElement) {
      overlayElement.addEventListener('click', handleOverlayClick);
    }

    return () => {
      if (overlayElement) {
        overlayElement.removeEventListener('click', handleOverlayClick);
      }
    };
  }, [isBodyOverlay5]);

  const [hideNavbar, setHideNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const shouldBeHidden = currentScrollPos > 100;

      setHideNavbar(shouldBeHidden);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const filteredItems = useMemo(
    () => props?.fields?.data?.menu?.navigation?.targetItem?.primary ?? [],
    [props?.fields?.data?.menu?.navigation?.targetItem?.primary]
  );
  const downloadData = useMemo(
    () => props?.fields?.data?.menu?.header_rightside?.targetItem?.children?.[0],
    [props?.fields?.data?.menu?.header_rightside?.targetItem]
  );
  const headerRightSide = useMemo(
    () => props?.fields?.data?.menu?.header_rightside?.targetItem,
    [props?.fields?.data?.menu?.header_rightside?.targetItem]
  );

  useEffect(() => {
    const handleScroll = () => {
      const content = document.querySelector('.nav_bar_wrapper');
      const contentMob = document.querySelector('.header_mob_nav');
      const scrollPosition = window.scrollY;

      if (content) {
        if (scrollPosition > 0) {
          content.classList.add('headerOnSCroll');
        } else {
          content.classList.remove('headerOnSCroll');
        }
      }

      if (contentMob) {
        if (scrollPosition > 0) {
          contentMob.classList.add('headerOnSCroll');
        } else {
          contentMob.classList.remove('headerOnSCroll');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const navStickyClass = isSticky ? 'nav_sticy' : '';

  useEffect(() => {
    const handleScroll = () => {
      const contentScroll = document.querySelector('.ticker-header-parent');
      const scrollPositionY = window.scrollY;

      // Check if scrolled down
      if (scrollPositionY > 0) {
        contentScroll.classList.add('tickerOpen-parent-scroll');
      } else {
        // If scrolled back to top, remove the class
        contentScroll.classList.remove('tickerOpen-parent-scroll');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [topNavDropdownState, setTopNavDropdownState] = useState(null);

  const handleTopNavDropdownChange = (newDropdownState) => {
    setTopNavDropdownState(newDropdownState);
  };

  useEffect(() => {
    if (topNavDropdownState !== null && typeof document !== 'undefined') {
      // Handle prim_left_nav
      const leftNavActiveElement = document.querySelector('.prim_left_nav .drop_a.active');
      if (leftNavActiveElement) {
        leftNavActiveElement.classList.remove('active');
      }

      // Handle prim_right_nav
      const rightNavActiveElement = document.querySelector('.prim_right_nav .drop_a.active');
      if (rightNavActiveElement) {
        rightNavActiveElement.classList.remove('active');
      }
      setActiveTab('');
      setIsBodyOverlay5(false);
      setIsShow(false);
      setIsToolsShow(false);
    }
  }, [topNavDropdownState]);

  return (
    <div className="ticker-header-parent tickerOpen-parent">
      <Ticker
        menuOpen={menuOpen}
        setBannerCountdownItem={setBannerCountdownItem}
        tickerData={props?.fields?.data?.menu?.tickers?.targetItem}
      />
      {!isMobile && (
        <header
          className={`nav_bar_wrapper headerSection hidden-xs hidden-sm ${
            hideNavbar ? 'headerOnSCroll' : ''
          }`}
        >
          <TopNavigation
            topNavigationProps={props?.fields?.data?.menu?.topnavigation?.targetItem}
            onDropdownChange={handleTopNavDropdownChange}
          />
          <div className="primary_navigation">
            <a href="/" className="brand_logo">
              <picture>
                <Image
                  field={
                    props?.fields?.data?.menu?.header_rightside?.targetItem?.company_log[0].Icon
                  }
                  loading="lazy"
                  alt="img"
                />
              </picture>
            </a>

            <div className="navWrap">
              <ul className="prim_left_nav">
                {filteredItems.map((x) => {
                  return (
                    <li key={x.id}>
                      {x?.tabs && x.tabs.length > 0 ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            switchTab(x.id);
                            setClickedTab(x?.menuTitle?.value);
                          }}
                          className={`drop_a ${activeTab === x.id ? 'active' : ''}`}
                        >
                          {x?.menuTitle?.value}
                        </a>
                      ) : (
                        <a href={x?.menuLink?.url} target={x?.menuLink?.target}>
                          {x?.menuTitle?.value}
                        </a>
                      )}
                    </li>
                  );
                })}
                <li>
                  <a
                    href={downloadData?.CTA.linkType === 'external' ? downloadData?.CTA.url : '#'}
                    className={downloadData?.CssClass?.value}
                    role="button"
                    tabIndex="0"
                    target={
                      downloadData?.CTA.linkType === 'external'
                        ? downloadData?.CTA.target
                        : undefined
                    }
                    onClick={() => {
                      handleMainNavClick(null, null, downloadData?.Label?.value);
                    }}
                  >
                    <Text field={downloadData?.Label} />
                    <Image field={downloadData?.Icon} alt="img" loading="lazy" />
                  </a>
                </li>
              </ul>

              <ul className="prim_right_nav">
                {headerRightSide?.children?.slice(1)?.map((item, index) => {
                  const isJavaScriptLink = item.CTA.linkType === 'javascript';
                  const linkHref = isJavaScriptLink ? '#' : item.CTA.url;

                  let itemClassName = item?.CssClass?.value ?? '';

                  if (
                    (isShow && item.CTA.text === 'quickservices_megamenu') ||
                    (isToolsShow && item.CTA.text === 'resources_megamenu')
                  ) {
                    itemClassName += ' active';
                  }
                  return (
                    <li key={index}>
                      {item.CTA.linkType === 'javascript' ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            if (item.CTA.text === 'quickservices_megamenu') {
                              toggleMegaMenu('quickservices_megamenu');
                            } else if (item.CTA.text === 'resources_megamenu') {
                              toggleToolsMegaMenu('resources_megamenu');
                            } else {
                              handleTabClick('searchmenu');
                            }
                            setClickedTab(item?.Label?.value);
                          }}
                          href={linkHref}
                          className={itemClassName}
                        >
                          <Image field={item?.Icon} alt="img" loading="lazy" />
                          <Text field={item?.Label} />
                        </a>
                      ) : (
                        <a
                          href={item.CTA.linkType === 'external' ? item.CTA.url : '#'}
                          className={item?.CssClass?.value}
                          role="button"
                          tabIndex="0"
                          target={item.CTA.linkType === 'external' ? item.CTA.target : undefined}
                        >
                          <Image field={item?.Icon} alt="img" loading="lazy" />
                          <Text field={item?.Label} />
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {bannerCountdownItem && <BottomTicker bannerCountdownItem={bannerCountdownItem} />}
        </header>
      )}
      {isMobile && (
        <MobileNavigation
          navigationResult={props?.fields?.data?.menu?.navigation?.targetItem}
          searchShortcuts={props?.fields?.data?.menu?.searchMenu?.targetItem?.children}
          headerRightSide={headerRightSide}
          toolsData={props?.fields?.data?.menu?.tools?.targetItem}
          quickServiceData={quickServiceData}
          topNavigationProps={props?.fields?.data?.menu?.topnavigation?.targetItem}
          drawer={props?.fields?.data?.menu?.drawer?.targetItem}
          getMenuOpen={setMenuOpen}
          mainLogo={props?.fields?.data?.menu?.header_rightside?.targetItem}
          bannerCountdownItem={bannerCountdownItem}
          disclaimerMessageData={props?.fields?.data?.menu?.disclaimerMessage?.targetItem}
        ></MobileNavigation>
      )}
      {/* // <SearchMenu
      //   className="cursor-pointer"
      //   activeTab={activeTab}
      //   searchShortcuts={props?.menu?.searchMenu?.children}
      //   relatedReads={props?.menu?.searchMenu?.relatedReadsFolder}
      //   dictionary={props?.i18n?.store?.data?.en?.translation}
      //  financialSolutionData={props?.fields?.data?.menu?.financialSolution?.targetItem}
      // ></SearchMenu> */}
      {!isMobile && (
        <MegaMenuHolder
          activeTab={activeTab}
          clickedTab={clickedTab}
          navigationResult={props?.fields?.data?.menu?.navigation?.targetItem}
          navStickyClass={navStickyClass}
          topNavDropdownState={topNavDropdownState}
        ></MegaMenuHolder>
      )}
      <MegaMenuQuickServices
        isShow={isShow}
        clickedTab={clickedTab}
        onQuickServiceLoad={setQuickServiceData}
        navStickyClass={navStickyClass}
        quickServicesData={props?.fields?.data?.menu?.quick_services}
      />
      <Tools
        isToolsShow={isToolsShow}
        clickedTab={clickedTab}
        onToolsLoad={setToolsData}
        navStickyClass={navStickyClass}
        toolsData={props?.fields?.data?.menu?.tools}
      />
    </div>
  );
};

export default withTranslation()(Header);
