import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const Countdown = (props) => {
  const { endTime, onExpire } = props;
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  });

  const getTimeRemaining = (endTime) => {
    const now = moment();
    const end = moment(endTime);
    const duration = moment.duration(end.diff(now));

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      expired: duration.asMilliseconds() <= 0,
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = getTimeRemaining(endTime);
      setTimeRemaining(remainingTime);

      if (remainingTime.expired && onExpire) {
        onExpire();
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [endTime, onExpire]);

  return (
    <div className="ticker_countDown_mb">
      <div className="counter_start_main">
        <div className="countdown_ul_main">
          <ul className="countdown_ul countdownContainer">
            <li className="countdown_li_item count_label_hidden">
              <span className="days">{timeRemaining.days}</span>
              <p>{props?.t('Day')}</p>
            </li>
            <li className="countdown_li_item count_label_hidden">
              <span className="hours">{timeRemaining.hours}</span>
              <p>{props?.t('Hour')}</p>
            </li>
            <li className="countdown_li_item count_label_hidden">
              <span className="minutes">{timeRemaining.minutes}</span>
              <p>{props?.t('Minute')}</p>
            </li>
            <li className="countdown_li_item count_label_hidden">
              <span className="seconds">{timeRemaining.seconds}</span>
              <p>{props?.t('Second')}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Countdown);
