import React, { useState, useEffect } from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import SubscribeToNewsLetter from '../../lib/subscribe-to-news-letter';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { handleAppDownloadCTAClick } from '../../lib/customGA4';

const SideMenuDownloadApp = (props) => {
  const graphQLResult = props?.fields;

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const metaverseModal = new bootstrap.Modal(document.getElementById('metaverse_modal_x1'));
  }, []);

  const validateAndSubmit = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || email.length > 50 || !re.test(email)) {
      setIsValid(false);
      setIsEmpty(!email);
      return false;
    } else {
      setIsValid(true);
      setIsEmpty(false);
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateAndSubmit(email)) {
      try {
        const message = await SubscribeToNewsLetter(email);
        if (message === 'Email is already Subscribed') {
          setResponseMessage(message);
          setIsValid(true);
          setIsEmpty(false);
          setEmail('');
        } else {
          setResponseMessage(message + '!');
        }
      } catch (error) {
        setResponseMessage('Unexpected error occurred');
        setIsValid(false);
        setIsEmpty(false);
        setEmail('');
      }
    } else {
      setIsValid(false);
      setIsEmpty(!email);
      setResponseMessage('');
    }
  };

  return (
    <div
      className="modal fade metaverse_right_pos downloadApp-modal_1"
      id="metaverse_modal_x1"
      tabIndex={-1}
      aria-labelledby="metaverse_modal_modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-slideout-right">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          <div className="modal-body">
            <div className="modal-header metaverse_right_pos_head">
              {graphQLResult?.stickyDownloadAppLogoVideo?.value?.url && (
                <div className="top-logo-video">
                  <DotLottiePlayer
                    className="leaflet-lottie-logo-anim"
                    src={graphQLResult?.stickyDownloadAppLogoVideo?.value?.url}
                    background="transparent"
                    speed={1}
                    autoplay
                    loop
                  ></DotLottiePlayer>
                </div>
              )}

              <RichText
                className="modal-title"
                id="metaverse_modal_modalLabel"
                field={graphQLResult?.heading}
              />
              <p className="popup-p">
                <Text field={graphQLResult?.subheading1} />
              </p>
            </div>
            <div className="modal_form_wrapper">
              <div className="footerSocial">
                {graphQLResult?.appleapplink?.value?.url && (
                  <a
                    href={graphQLResult?.appleapplink?.value?.url}
                    onClick={() =>
                      handleAppDownloadCTAClick(
                        graphQLResult?.appleapplink?.text,
                        graphQLResult?.heading?.value
                      )
                    }
                  >
                    <Image field={graphQLResult?.appleappimage} className="icon3" loading="lazy" />
                    {graphQLResult?.appleapplink?.value?.text}
                  </a>
                )}
                {graphQLResult?.googleappLink?.value?.url && (
                  <a
                    href={graphQLResult?.googleappLink?.value?.url}
                    onClick={() =>
                      handleAppDownloadCTAClick(
                        graphQLResult?.googleappLink?.text,
                        graphQLResult?.heading?.value
                      )
                    }
                  >
                    <Image field={graphQLResult?.googleappimage} className="icon3" loading="lazy" />
                    {graphQLResult?.googleappLink?.value?.text}
                  </a>
                )}
              </div>
              <div className="footer-midRightTop">
                <Image
                  field={graphQLResult?.image2}
                  className="img-fluid"
                  alt="footer logo"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SideMenuDownloadApp);
