import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import Countdown from '../ticker/Countdown.js';

const BottomTicker = ({ bannerCountdownItem }) => {
  const [expired, setExpired] = useState(false);

  const handleExpire = useCallback(() => {
    setExpired(true);
    if (typeof document !== 'undefined') {
      const offerCountdownTicker = document.querySelector('.warmYellowTickr');
      if (offerCountdownTicker) {
        offerCountdownTicker.classList.add('ticker_hidden');
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const closeButtons = document.querySelectorAll('.ticker_close');

      closeButtons.forEach((button) => {
        button.onclick = function () {
          const wrapper = this.closest('.ticker_wrapper');
          if (wrapper) {
            wrapper.classList.add('ticker_hidden');
          }
        };
      });

      // Cleanup event listeners on unmount
      return () => {
        closeButtons.forEach((button) => {
          button.onclick = null;
        });
      };
    }
  }, []);

  return (
    <div className="ticker_wrapper offer_countDown_ticker ticker_wrapper_centered bottomTickerWrapper warmYellowTickr">
      <div className="ticker_container">
        <div className="ticker_row">
          <div className="ticker_content">
            <RichText field={bannerCountdownItem?.Content} />
          </div>
          <div className="ticker_buy_button">
            <Countdown
              endTime={moment(bannerCountdownItem?.OfferEndTime?.value, 'YYYYMMDDTHHmmssZ').format(
                'YYYY-MM-DDTHH:mm:ss'
              )}
              onExpire={handleExpire}
            />
            {bannerCountdownItem?.CTAButton?.url && (
              <a
                className="buy_online_ticker"
                href={bannerCountdownItem?.CTAButton?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {bannerCountdownItem?.CTAButton?.text}
                {bannerCountdownItem?.CTAIcon?.src && <Image src={bannerCountdownItem?.Icon} />}
              </a>
            )}
          </div>
        </div>
        {bannerCountdownItem?.Icon?.src && (
          <div className="ticker_close" onClick={handleExpire}>
            <img src={bannerCountdownItem?.Icon?.src} alt="Ticker Close" />
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomTicker;
